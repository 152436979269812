import { MovieType, StreamingContentType, WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import { isMovieType, isStreamingContentType, isWatchableContentType } from "src/lib/movieinfo/typeGuards";
import { IFilmomtale, isFilmomtale } from "src/lib/types/Filmomtale";

//#region [Props]
type OptionalPosterInfoProps = {
    optionalDisplay?: "year";
    film: IFilmomtale | MovieType | StreamingContentType | WatchableContentType;
    className?: string;
};
//#endregion

//#region [Component]
export default function OptionalPosterInfo({ optionalDisplay, film, className }: OptionalPosterInfoProps) {
    if (!optionalDisplay) return null;
    let optInfo: string | undefined;

    if (isStreamingContentType(film)) {
        optInfo = film.year;
    } else if (isWatchableContentType(film)) {
        optInfo = film.productionYear;
    } else if (isFilmomtale(film)) {
        optInfo = film.year?.toString();
    } else if (isMovieType(film)) {
        optInfo = film.productionYear;
    }
    if (!optInfo) return null;
    return <div className={className}>{optInfo}</div>;
}
//#endregion
