import theme from 'src/components/themes/DefaultTheme';
import { StreamingProviderContentType } from 'src/lib/movieinfo/movieinfotypes';
import styled from 'src/lib/styles/css';


//#region [Props]
type StreamingProviderProps = {
	providerContent: StreamingProviderContentType;
	className?: string;
};
//#endregion

//#region [Component]
export default function StreamingProvider({ providerContent, className }: StreamingProviderProps) {
	const url = addAffiliate(providerContent.url, providerContent.id);
	return <SProviderLink href={url} target="_blank" className={className} onClick={() => track(providerContent)}>
		<SImg src={providerContent.provider!.logo} alt={providerContent.provider?.name ?? "Innholdsleverandør"}/>
	</SProviderLink>;
}
//#endregion

//#region [Component]
function addAffiliate(href: string, providerId: number) {
	let newHref = href;
	switch (providerId) {
		case 26:
			newHref = `https://hbomax.prf.hn/click/camref:1011lqFg9/pubref:filmweb/ar:filmweb_SG/destination:${href.replace(/:/g, '%3A').replace(/\//g, '%2F')}`;
			//console.debug("Adding affiliate", href, newHref);
			break;
		default:
	}
	return newHref;
}

function track(providerContent: StreamingProviderContentType) {
	window?.dataLayer?.push({
		'event': 'Streamingguiden',
		'eventCategory': 'Streamingklikk',
		'eventAction': providerContent.provider?.name,
		'eventLabel': providerContent.title,
		'eventStreamingId': providerContent.id
	});
}
//#endregion

//#region [Styles]
const SProviderLink = styled.a`
	width: 160px;
	height: 65px;

	background-color: ${theme.palette.lillaMørkHovedfarge};
	border: 2px solid ${theme.textColor};
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.textColor};
`;

const SImg = styled.img`
	width: auto;
	max-width: calc(100% - 30px);
	max-height: calc(100% - 10px);
`;
//#endregion