import { MovieType, StreamingContentType, WatchableContentType } from "src/lib/movieinfo/movieinfotypes";

export function isMovieType(t?: any): t is MovieType {
    return t?.__typename === "MovieType" || t?.__typename === "Movie";
}

export function isStreamingContentType(t?: any): t is StreamingContentType {
    return t?.__typename === "StreamingContentType" || t?.__typename === "StreamingContent";
}

export function isWatchableContentType(t?: any): t is WatchableContentType {
    return t?.__typename === "WatchableContentType" || t?.__typename === "WatchableContent";
}