import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
//#region [Styles]
const SKinoklubbPosterBanner = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 29px;
	z-index: 100;
	background-color: var(--kinoklubbcolor);
	color: ${theme.textColorDark};
	text-align: center;
	line-height: 10px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		height: 44px;
		line-height: 16px;
		font-size: 16px;
	}
	@media ${theme.mq.desktop} and (max-width: 1280px) {
		//@media (orientation: landscape) and (max-width: 1280px) {
		height: 44px;
		line-height: 14px;
		font-size: 14px;
	}
`;
//#endregion

//#region [Component]
export default function KinoklubbPosterBanner() {
	return <SKinoklubbPosterBanner>Halv pris med Kinoklubben</SKinoklubbPosterBanner>;
}
//#endregion